import * as React from "react";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, Button, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import axios from "../Axios/axiosConfig";
import Captcha from "./Captcha";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// material-ui
// import { useTheme } from '@mui/material/styles';

export default function PublishModel({
  isOpen,
  handlePostText,
  editorState,
  title,
  toggleSnackBar,
  setSnackbarType,
  files,
  setUploadProgress,
}) {
  const customization = useSelector((state) => state.customization);
  // const theme = useTheme();
  const [isCaptchaValid, setIsCaptchaValid] = React.useState(false);
  const [error, setError] = React.useState("");
  const [codeText, setCodeText] = React.useState("");
  const [typeCodeText, setTypeCodeText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  // const handleOpen = () => setOpen(isOpen || true);

  const handleSubmitPost = () => {
    // Set a loading state to display a loading spinner
    setLoading(true);

    // New editor code
    const rawContentData = editorState;
    //let validateEditorText = rawContentData.blocks && rawContentData.blocks.length > 0 ? rawContentData.blocks[0].text : '';
    let isValidated = validation();

    if (isValidated) {
      const content = JSON.stringify(rawContentData);

      // Create a FormData object to send the data
      const formData = new FormData();
      formData.append("storedHtmlContent", content);
      formData.append(
        "uniqueId",
        uuidv4().split("-").join("").substring(0, 6) || "12345"
      );
      formData.append("title", title);
      formData.append("name", localStorage.getItem("name"));
      formData.append("imgUrl", localStorage.getItem("imgUrlvalue"));

      // Log FormData entries
      formData.forEach((value, key) => {
        console.log(key, value);
      });

      // Append files to FormData if they exist
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      // Make API request with axios
      axios
        .post("/publish/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to FormData
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        })
        .then((response) => {
          handleClose();
          setSnackbarType("postSuccess");
          toggleSnackBar();
          if (response.data && response.data.url) {
            let createLinkArray = response.data.url.split("/");
            let getFileName = createLinkArray[createLinkArray.length - 1];
            //navigate(getFileName);
            //window.location.reload();
          }

          // Hide loading spinner after a successful response
          setLoading(false);
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            setError("Given Code already exists in the system. Try a new code");
          } else {
            handleClose();
            setSnackbarType("failed");
            toggleSnackBar();
            console.error(error);
          }

          // Hide loading spinner after an error
          setLoading(false);
        })
        .finally(() => {
          setUploadProgress(0); // Hide loading spinner
        });
    } else {
      // Handle validation failure
      console.log("Validation failed");
      setLoading(false);
    }
  };

  const validation = () => {
    if (!isCaptchaValid) {
      if (customization.user.name !== "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const handleTypeCode = (event) => {
    if (event.target.value?.length > 3) {
      setError("");
    }
    setTypeCodeText(event.target.value);
    setCodeText(event.target.value);
  };
  const handleClose = () => {
    setTypeCodeText("");
    setCodeText("");
    setError("");
    handlePostText();
  };

  const generateCode = () => {
    setTypeCodeText("");
    const alphanumericCode = uuidv4().split("-").join("").substring(0, 6);
    setCodeText(alphanumericCode);
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      // Trigger button click when Enter key is pressed
      handleSubmitPost();
    }
  };
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        {loading ? ( // Conditionally render loading spinner or form content
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="secondary" size={64} />{" "}
            {/* Display a loading spinner */}
          </div>
        ) : (
          <Box sx={style} onKeyDown={handleEnterKey}>
            <Typography variant="h3" align="center" gutterBottom>
              Publish Live
            </Typography>
            {/* <Typography variant="body1" color="error" align="center">
              Due to maintenance, this feature will be disabled until December
              25, 2024. We apologize for the inconvenience.
            </Typography> */}

            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {customization && customization.user.name === "" && (
                <Captcha setIsCaptchaValid={setIsCaptchaValid} />
              )}
              <Grid item xs={12} sm={6}>
                <Button
                  disableElevation
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmitPost}
                >
                  Submit
                </Button>
              </Grid>
              <Typography
                variant="p" 
                align="center"
                sx={{
                  borderRadius: "8px", // Rounded corners
                  padding: "12px 20px", // Padding for better spacing
                  fontWeight: "bold", // Bold text for emphasis
                  marginTop: "20px", // Space above the message
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow
                }}
              >
                Your post will go live in 2 - 5 days. Check the <strong>Live Page</strong> for updates.
              </Typography>
            </Grid>
          </Box>
        )}
      </Modal>
    </div>
  );
}
