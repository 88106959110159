import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, TextField, Button, CircularProgress } from "@mui/material";
import instance from "../Axios/axiosConfig";

const LivePage = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 20;
  const [totalPages, setTotalPages] = useState(1);

  const fetchPosts = async (page, title = "") => {
    setIsLoading(true);
    try {
      const response = await instance.post(
        "/publish/search",
        { page: page - 1, limit: itemsPerPage, title: title },
        { headers: { "Content-Type": "application/json" } }
      );

      const fetchedPosts = response.data.posts || [];
      setPosts(fetchedPosts);
      setTotalPages(Math.ceil(response.data.total / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(page);
  }, [page]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length >= 3) {
      fetchPosts(1, e.target.value);
    } else if (e.target.value.length === 0) {
      fetchPosts(1);
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      {/* Search Section */}
      <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
        <TextField
          label="Search by Title"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
          sx={{ maxWidth: "400px" }}
        />
      </Box>

      {/* Posts Grid */}
      <Grid container spacing={3}>
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={post._id}>
            <Box
              sx={{
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "16px",
                backgroundColor: "#fff",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box", // Enables truncation
                  WebkitBoxOrient: "vertical", // Sets the box orientation for line clamping
                  WebkitLineClamp: 2, // Limits to 2 lines
                  lineHeight: "1.5", // Adjust line height for readability
                  maxHeight: "3rem", // Ensure max height matches 2 lines
                }}
                title={post.title}
              >
                {post.title || "Untitled"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "gray",
                  marginBottom: "10px",
                }}
              >
                Visits: {post.visits || 0} | Likes: {post.likes || 0} | Dislikes:{" "}
                {post.dislikes || 0}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={`https://justpasteit.in/publish/${post.uniqueId}.html`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textTransform: "none",
                }}
              >
                View
              </Button>
            </Box>
          </Grid>
          
          ))
        )}
      </Grid>

      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
          sx={{ marginRight: "10px" }}
        >
          Previous
        </Button>
        {[...Array(totalPages).keys()].map((index) => (
          <Button
            key={index}
            onClick={() => setPage(index + 1)}
            variant={page === index + 1 ? "contained" : "outlined"}
            sx={{
              minWidth: "40px",
              margin: "0 5px",
            }}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          variant="contained"
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={page === totalPages}
          sx={{ marginLeft: "10px" }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default LivePage;
