// assets
import * as SVGIcons  from "../SVGIcons/SVGIcons";

// constant
const icons = { IconTypography: SVGIcons.IconTypography, IconGoogle: SVGIcons.Publish, IconLivePage: SVGIcons.livePage };



// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const pastetext = {
  id: 'pastetext',
  title: 'Popular Tools',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Paste Text',
      type: 'item',
      url: '/',
      icon: icons.IconTypography,
      breadcrumbs: false
    },
    {
      id: 'publish',
      title: 'Publish Page',
      type: 'item',
      url: '/publish',
      icon: icons.IconGoogle,
      breadcrumbs: false
    },
    {
      id: 'livePage',
      title: 'Live Page',
      type: 'item',
      url: '/livePage',
      icon: icons.IconLivePage,
      breadcrumbs: false
    }
  ]
};

export default pastetext;
