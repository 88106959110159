import React, { useEffect } from "react";

const PayPalButton = ({ amount, currency = "USD", onSuccess, onError }) => {
  useEffect(() => {
    // Dynamically load the PayPal script if not already loaded
    if (!window.paypal) {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=AbxGSKkq2KljL9ech5S0I7gWfHvHZ_PiFBfZkTgXV8QMmLopZ5vJq3A-29ClS5AiGceAebalQhbqFWMz&currency=${currency}`;
      script.async = true;
      script.onload = () => initializePayPalButton();
      document.body.appendChild(script);
    } else {
      initializePayPalButton();
    }
  }, []);

  const initializePayPalButton = () => {
    if (window.paypal && window.paypal.Buttons) {
      window.paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "blue",
            shape: "rect",
            label: "paypal",
          },
          createOrder: (data, actions) => {
            return actions.order.create({
              payment_source: {
                paypal: {
                  experience_context: {
                    payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                    landing_page: "LOGIN",
                    shipping_preference: "GET_FROM_FILE",
                    user_action: "PAY_NOW",
                    return_url: "https://justpasteit.in",
                    cancel_url: "https://justpasteit.in",
                  },
                },
              },
              purchase_units: [
                {
                  amount: {
                    value: amount, // Set the transaction amount
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              console.log("Transaction completed:", details);
              if (onSuccess) onSuccess(details);
            });
          },
          onError: (err) => {
            console.error("PayPal button error:", err);
            if (onError) onError(err);
          },
        })
        .render("#paypal-button-container");
    }
  };

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
