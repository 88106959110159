import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
} from "@mui/material";
import ProfileSection from "./profileSection";
import LogoSection from "./LogoSection";
import * as SVGIcons from "../components/SVGIcons/SVGIcons";
import PaymentButton from "../components/payment/PaymentButton";

const Menuicon = SVGIcons.MenuIcon;

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  // State for opening the dialog
  const [open, setOpen] = useState(false);

  // Handle opening and closing the modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
          aria-label="Logo Section"
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
          }}
          aria-label="Menu Button"
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            alt="menu list"
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <Menuicon stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* Try Premium Button */}
      {/* <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}
      >
        Try premium
      </Button> */}

      <ProfileSection />
      <PaymentButton open={open} handleClose={handleClose} /> 
    </>
  );
};

export default Header;
